import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Skeleton from 'react-loading-skeleton';



export default class Size extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.goBack = this.goBack.bind(this);
        
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            level:40,
        };
        const { setData,setLastTechnicalInformationsOpen } = this.context;
        setData(params);
        setLastTechnicalInformationsOpen(this.props.match.params.second);
    }
    componentWillUnmount()
    {
       
    }
    goBack()
    {
        //console.log(this.props.match.params.first)
        //this.props.match.params.first
        //this.props.history.goBack();
    }
    render() 
    {
        const { loading, structure ,size} = this.context;
        const { first, second,language} = this.props.match.params;
        if ( structure.technical_informations  )
        {
            let current
            structure.technical_informations.pagine.forEach(function(item)
            {
                if (item.slug==first)
                {
                    item.child.forEach(function(capitolo)
                    {
                        if (second==capitolo.slug)
                        {
                            current=capitolo;
                           
                        }
                    })
                }
            })
            if (current)
            {
                return (
                    <div>
                        <div className='main background-grey technical-informations-page '>
                            <div className="main-sep"></div>
                            <div className='close-fixed-top'>
                                <Container>
                                    <Row >
                                        <Col>
                                            <Link className='btn' to={ "/"+language+"/technical-informations/"+first+"/" } > {structure.technical_informations.back}</Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Container className="pinch-container">
                                <Row>
                                    <Col>
                                        <PinchZoomPan zoomButtons={false}  maxScale={3} >
                                            <div className="ti-container">
                                                <img src={current.immagine} className="img-fluid " />
                                            </div>
                                        </PinchZoomPan>
                                         <div  className='h-f'></div>
                                    </Col>
                                </Row>
                            </Container>
                            <div className='close-fixed-bottom'>
                                <Container>
                                    <Row >
                                        <Col>
                                            <Link className='btn' to={ "/"+language+"/technical-informations/"+first+"/" } > {structure.technical_informations.back}</Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>  
                    </div>
                );
            }
        }else{
            return(<div>
            <div className='main'>
                <div className="main-sep"></div>
                <Container>
                    <Row>
                        <Col>
                            <Skeleton/><Skeleton  /><Skeleton height={250} /><Skeleton/><Skeleton/><Skeleton width={250}/>
                        </Col>
                    </Row>
                </Container>
                </div></div>);
        }
    }
    
}
 
 /*
  * 

  */