import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import SizeList from './../helpers/SizeList'
import Scroller from './../helpers/Scroller'
import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab, InputGroup,FormControl   } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from './../helpers/Breadcrumb';


export default class TechnicalData extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        
    }
    componentDidMount()
    {
         
        const params={
            language:this.props.match.params.language,
            structure:1,
            size_list:1,
            level:20,
        };
        const { setData,setSearchSizeKey,scroll_to } = this.context;
        setData(params);
       
    }
  
    handleClick() 
    {
        //const { history,searchKey,language } = this.context;
        //history.push("/"+language+"/technical-data/"+searchKey+"/");
    }
    onChangeKeySearch(e)
    {
        if(e.charCode==13)
        {
                e.target.blur();
//            this.handleClick();
        }else{
            const { setSearchSizeKey} = this.context;
            setSearchSizeKey(e.target.value);
        }
    }
    
    render() 
    {
        const { structure, loading,searchSizeKey,size_list, language } = this.context;
        let size_search=new Array();
        size_list.forEach(function(item)
        {
            if (
                !searchSizeKey || 
                (item.description && item.description.toLowerCase().indexOf(searchSizeKey.toLowerCase())!=-1) ||
                (item.third && item.third.toLowerCase().indexOf(searchSizeKey.toLowerCase())!=-1)
            )
            {
                size_search.push(item)
            }
        });
        if (size_list.length !=0 )
        {
            
            return (
                <div>
                    <div className='main technical-data'  id="technical-data">
                    <Breadcrumb current={structure.technical_data.titolo}></Breadcrumb>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.technical_data.titolo}}></div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className='symbol'>
                                        <Link to={'/'+language+'/technical-data-symbols/'} >
                                            <div className='btn'>
                                                {structure.technical_data.symbol_button}
                                            </div>  
                                        </Link>
                                    </div>
                                </Col>    
                                <Col xs={12}>    
                                    <div className="search-box d-flex align-items-center"> 
                                        <InputGroup className="mb-3">

                                        <FormControl
                                            placeholder={structure.technical_data.search_placeholder}
                                            value={searchSizeKey}
                                            aria-label={structure.technical_data.search_placeholder}
                                            aria-describedby="basic-addon2"
                                            onKeyPress={this.onChangeKeySearch.bind(this)}
                                            onChange={this.onChangeKeySearch.bind(this)}
                                        />
                                        <InputGroup.Append>
                                                <Button variant="white" onClick={this.handleClick.bind(this)} ><img src={structure.technical_data.search_icona} className="img-fluid search-icon" /></Button>
                                        </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                    <div className="result">
                                        <SizeList list={size_search} />
                                             
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    
                    <Scroller  id="technical-data" />
                </div>
                      
            );
        }else{
            return (
                    
                <div>
                    <div className='main technical-data'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="titolo">
                                        <Skeleton />
                                    </div>

                                    <div style={{marginTop: '14px'}}> 
                                    <Skeleton  height={30}/>
                                        <Skeleton  height={40}/>
                                        
                                    </div>
                                    <div className="result">
                                        <Skeleton width={250} />
                                        <Skeleton width={200} />
                                        <Skeleton width={300} />
                                        <Skeleton width={220} />
                                        <Skeleton width={250} />
                                        <Skeleton width={200} />
                                        <Skeleton width={220} />
                                        <Skeleton width={210} />
                                        <Skeleton width={210} />
                                        <Skeleton width={300} />
                                        <Skeleton width={250} />
                                        <Skeleton width={200} />
                                        <Skeleton width={300} />
                                        <Skeleton width={220} />
                                        <Skeleton width={210} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
              </div>
                    
                    
                    
                    );
            
        }
            
    }
    
}

