import React from 'react';
import { Link,useHistory  } from 'react-router-dom';   
import AppContext from './../helpers/AppContext'
import { TransitionGroup ,CSSTransition } from 'react-transition-group'
import { Container, Row, Col,InputGroup,Form, FormControl, Button } from 'react-bootstrap';
import { HamburgerCollapse } from 'react-animated-burgers'

export default class Topbar extends React.Component 
{
    static contextType = AppContext;
    
    state = {
        isActive: false
    }
    constructor(props) 
    {
        super(props);
       
     
    }
    toggleButton = () => {
        this.setState({
          isActive: !this.state.isActive
        })
    }
    componentDidMount()
    {
        
    }
    render() {
        const { structure, language } = this.context;
        this.show=this.props.location_pathname !='/';
        
        if(structure.common){
            if (this.state.isActive)
            {
                document.body.classList.add('sidemenu-open');

            }else{
                document.body.classList.remove('sidemenu-open');

            }
            return (
                <CSSTransition in={this.show} timeout={500} classNames="slide" unmountOnExit>
                    <div>
                        <div className='topbar' >
                        <div className='hamburger-container'>
                            <HamburgerCollapse isActive={this.state.isActive} toggleButton={this.toggleButton} buttonColor="transparent" barColor="white" />
                        </div>
                        <div className='text-center header-logo'><Link to={"/"+language+"/"}><img src={structure.common.header_logo} className='' /></Link></div>
                        </div>
                        <SideMenu isActive={this.state.isActive} toggleButton={this.toggleButton} />
                    </div>
                </CSSTransition>
            )
        }else{
            return (
                    <div>
                        <div className='topbar' >
                        
                        </div>
                       
                    </div>
                        
                        );
        }
       
    }
    
}


class SideMenu extends React.Component 
{
    static contextType = AppContext;
    render() {
        const { structure } = this.context;
        return (
            <div className='sidemenu'>
    
                <CSSTransition in={this.props.isActive} timeout={250} classNames="opacity-side-menu" unmountOnExit>
                    <div className='background' >
                    </div>
                </CSSTransition>
                <CSSTransition in={this.props.isActive} timeout={250} classNames="slide-side-menu" unmountOnExit>
                        <div className='menu '>
                            {structure.common && <SideMenuSearch  toggleButton={this.props.toggleButton} titolo={structure.common.search_titolo} icona={structure.common.search_icona} /> }
                            {structure.common && 
                                structure.common.sidemenu.map((voce, i) => {  
                                    
                                    return (<SideMenuVoce toggleButton={this.props.toggleButton} key={"sidemenu-"+voce.url} titolo={voce.titolo} url={voce.url} />) 
                                })     
                    
                            }
                            <div className='m-f'></div>
                        </div>
                </CSSTransition>
                
            </div>
        )
    }
}

class SideMenuSearch extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        
     
    }
    componentDidMount()
    {
        const { searchProductKey } = this.context;
        this.setState({
            val: searchProductKey,
        });
    }
    handleClick() 
    {
        this.props.toggleButton();
        const { history,setSearchProductKey,language } = this.context;
        setSearchProductKey(this.state.val);
        history.push("/"+language+"/product-search/");
        
    }
    onChangeKeySearch(e)
    {
        if(e.charCode==13)
        {
            e.target.blur();
            this.handleClick();
        }else{
            this.setState({ val: e.target.value });
        }
    }
    render()
    {
        const {searchProductKey} = this.context;
        return(  
            <div className="sidemenu-search d-flex align-items-center"> 
                <InputGroup className="mb-3 sidemenu-search-box">
                <FormControl
                    placeholder={this.props.titolo}
                    defaultValue={searchProductKey}
                    aria-label={this.props.titolo}
                    aria-describedby="basic-addon2"
                    onKeyPress={this.onChangeKeySearch.bind(this)}
                    onChange={this.onChangeKeySearch.bind(this)}
                />
                <InputGroup.Append>
                        <Button variant="white"  onClick={this.handleClick.bind(this)} ><img src={this.props.icona} className="img-fluid search-icon" /></Button>
                </InputGroup.Append>
                </InputGroup>
            </div>
        )
    }
}


class SideMenuVoce extends React.Component 
{
    static contextType = AppContext;
    
    handleClick() 
    {
        this.props.toggleButton();
            const { history } = this.context;
            history.push(this.props.url);
    }
    render()
    {
        
         if (this.props.url.startsWith("https://"))
        {
            
            return(
                <a className="sidemenu-item d-flex align-items-center" href={this.props.url} target="_blank" dangerouslySetInnerHTML={{__html: this.props.titolo}}></a>
            )
        }else{
            return(
                <div className="sidemenu-item d-flex align-items-center" onClick={this.handleClick.bind(this)} dangerouslySetInnerHTML={{__html: this.props.titolo}}></div>
            )
            
        }

            
    }
}