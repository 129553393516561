import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';

export default class TechnicalDataSymbols extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.goBack = this.goBack.bind(this);
    }
    goBack()
    {
        
        this.props.history.goBack();
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            level:30,
        };
        const { setData } = this.context;
        setData(params);
    }
    render() {
       
        const { structure,language } = this.context;
        if (structure && structure.technical_data)
        {
            return (

               <div>
                    <div className='main technical-data-symbols' id="technical-data-symbols">
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className='content' dangerouslySetInnerHTML={{__html: structure.technical_data.symbol_page}}></div>
                                    <div  className='h-f'></div>
                                </Col>
                            </Row>
                            
                        </Container>
                        <div className='close-fixed-bottom'>
                            <Container>
                                <Row >
                                    <Col>
                                    <div className='btn btn-back' onClick={this.goBack}>
                                        {structure.technical_data.symbol_back}
                                    </div>  
                                       
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
              </div>

            );
        }else{
            return ('');
        }
    }
    
}
