import React from 'react';
import AppContext from './../helpers/AppContext';

export default class Scroller extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.prev_key='';
    }
    componentWillUnmount()
    {
       
        const { setMemScroll } = this.context;
        if (this.props.id && document.getElementById(this.props.id))
        {
            setMemScroll(this.props.id,document.getElementById(this.props.id).scrollTop );
        }
    }
    componentDidMount()
    {
        const { memScroll } = this.context;
        if(this.props.id &&  memScroll[this.props.id] && document.getElementById(this.props.id))
        {
            document.getElementById(this.props.id).scroll(0, memScroll[this.props.id])
        }
    }
    render() 
    {
        return('');
    }
    
}
