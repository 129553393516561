import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import SizeList from './../helpers/SizeList'
import Scroller from './../helpers/Scroller'
import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from './../helpers/Breadcrumb';

export default class Product extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.goBack = this.goBack.bind(this);
    }
    goBack()
    {
        
        this.props.history.goBack();
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            size_list:1,
            product:{slug:this.props.match.params.slug},
            level:30,
        };
        
        const { setData, product , setLastProductOpen} = this.context;
        setData(params);
        setLastProductOpen(this.props.match.params.slug);
    }
    componentWillUnmount()
    {
        const { resetProduct} = this.context;
        resetProduct();
    }
    render() {
       
        const { structure, product,size_list, loading, scroll_to} = this.context;

        if (Object.keys(product).length  )
        {
            let size_product=new Array();
            if (product.tread_pattern) {
                size_list.forEach(function(item)
                {
                    if (product.tread_pattern.includes(item.third))
                    {
                        
                        size_product.push(item)
                    }
                });
            }
            return (
            <div className='m-f'>
                <div className='main product' id={'product-'+product.slug}>
                    <Breadcrumb current={product.titolo}></Breadcrumb>
                    <div className="main-sep"></div>
                    <Container>
                        <Row>
                            <Col>
                             <div className= 'titolo' dangerouslySetInnerHTML={{__html:  product.titolo}}></div>
                            { product.back && (<Link className='btn mt-5' key={'back-'+product.back.url} to={product.back.url} dangerouslySetInnerHTML={{__html: product.back.label}}></Link>) }
                                    
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                
                                {  product.sotto_immagine && (<div className='sotto-immagine' dangerouslySetInnerHTML={{__html: product.sotto_immagine}}></div>) }
                                { product.categorie && product.categorie.length && (
                                <Row className='box-categoria-prodotto'>
                                    {product.categorie.map(function(item, i){
                                        return(<Col xs={12} key={'product-cat-'+i} ><Link className='btn-white categoria-prodotto' key={'prodotto-cat-link-'+item.url} to={{ pathname: item.url }} dangerouslySetInnerHTML={{__html: item.label}}></Link></Col> );
                                      })
                                    }
                                </Row>   
                                )}
                            </Col>
                            <Col xs={12} className= 'text-center'>
                                <div className= 'descrizione' dangerouslySetInnerHTML={{__html: product.descrizione}}></div>
                                {  product.immagine && ( <img className='img-fluid mx-auto' src={product.immagine} />) }
                                { size_product && size_product.length!=0 && (
                                <div>
                                    <div className="size-titolo">
                                        { product.size_titolo }
                                    </div>
                                    <SizeList list={size_product} />
                                </div>
                                )}
                             </Col>
                             <Col xs={12}>
                             
                                { product.categorie && product.categorie.length && (
                                <Row className='box-categoria-prodotto'>
                                    
                                            <Col xs={12} key={'product-cat-'} ><div className='close-fixed-bottom mr-3 ml-3'><div onClick={this.goBack} className='btn btn-back categoria-prodotto '   dangerouslySetInnerHTML={{__html: structure.common.back}}></div></div></Col>
                                        
                                </Row>   
                                )}
                             </Col>
                            </Row>
                    </Container>
                </div>
                <Scroller  id={'product-'+product.slug} />
            </div>
            );
    
        }else{
            return (
            <div>
                <div className='main product'>
                    <div className="main-sep"></div>
                    <Container>
                        <Row>
                            <Col>
                                <div className= 'titolo' ><Skeleton /></div>   
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} >
                                <div> <Skeleton /> <Skeleton /> </div>
                            </Col>
                            <Col xs={12} >
                                    <div className= 'descrizione'> <Skeleton height={200} /> <Skeleton /> <Skeleton /> <Skeleton  /><Skeleton  /> <Skeleton/> <Skeleton width={250} /> <Skeleton/> <Skeleton/> <Skeleton height={300} /> <Skeleton/></div>
                             </Col>
                            </Row>
                    </Container>
                </div>
            </div>
            );
        }
            
    }
    
}

