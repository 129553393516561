import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import Scroller from './../helpers/Scroller'
import SizeParams from './../helpers/SizeParams'

import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab, InputGroup,FormControl, Form  } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from './../helpers/Breadcrumb';



export default class TireSizeCorrispondenceResult extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.sri=null;
        this.selectedSize=null;
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            size_list:1,
            level:30,
        };
        const { setData } = this.context;
        setData(params);
    
        
    }
    componentWillUnmount()
    {
        const { resetSize} = this.context;
        resetSize();
    }
    
    get_data()
    {
        let output=[];
        //console.log(this.props.match.params.size)
        let _this=this;
        const { structure, loading, language, size_list,lastTSCOpen } = this.context;
        if (size_list.length)
        {
            // filtrare per SRI
           output = size_list.filter( function(arr) {

                if(arr['ID_code']!=_this.selectedSize.ID_code && arr['SRI']==_this.selectedSize.SRI)
                {
                    return true;
                }else{
                    return false;
                }
                
                return (arr = true);
            });
        
        
        }
        return output;
    }
    checkLastTSCOpen()
    {
        const { structure, loading, language, size_list,setLastTSCOpen } = this.context;
        if (size_list.length)
        {
            size_list.map((key, i) => 
            {
                if(key.ID_code==this.props.match.params.size)
                {
                    setLastTSCOpen(key);
                    this.selectedSize=key;
                }
            })
        }
        
    }
    render() 
    {
        
        const { structure, loading, language, size_list,lastTSCOpen } = this.context;
        if (size_list.length !=0 )
        {
            if (!lastTSCOpen)
            {
                this.checkLastTSCOpen();
            }else{
                this.selectedSize=lastTSCOpen;
            }
             
            let size_lista=this.get_data();
            let value;
            let key='';
            let lista_parametri=new Array();
            let result_lista_parametri=new Array();
            let result_key='';
            
            let lista=structure.tire_size_corrispondence.select_lista.concat(structure.tire_size_corrispondence.auto_lista)
            
            Object.keys(lista).map((index, i) => 
            {     
                key=lista[Object.keys(lista)[index]]
                lista_parametri.push({titolo:key.titolo,value:this.selectedSize[key.variabile]});
            })
           
            return (
                <div>
                    <div className='main tire-size-corrispondence-result'  id="tire-size-corrispondence-result">
                    <Breadcrumb current={structure.tire_size_corrispondence.titolo} btn_link={"/"+language+"/tire-size-correspondence/"} btn_label={structure.tire_size_corrispondence.risultati_back}></Breadcrumb>
                        <div className="main-sep"></div>
                        <Container >
                            <Row>
                                <Col xs={12}>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.tire_size_corrispondence.titolo}}></div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        
                        <SizeParams titolo={structure.tire_size_corrispondence.risultati_scheda_titolo} containerClass='' lista={lista_parametri} ></SizeParams>
                     
                        <Container>                
                            <Accordion >

                                { size_lista.map((voce, i) => {
                                    result_lista_parametri=new Array();
                                    Object.keys(lista).map((index, i) => 
                                    {     
                                        result_key=lista[Object.keys(lista)[index]]
                                        result_lista_parametri.push({titolo:result_key.titolo,value:voce[result_key.variabile]});
                                    })
                                    
                                    return (
                                        <div key={'accordion-results'+i}>
                                                <Accordion.Toggle as={Button} variant="size" eventKey={i+1}>
                                                  {voce.description}
                                                </Accordion.Toggle>

                                              <Accordion.Collapse eventKey={i+1}>
                                               <SizeParams containerClass='accordion' lista={result_lista_parametri} ></SizeParams>
                                              </Accordion.Collapse>
                                        </div>
                       
                                    );
                                    
                                })}



                            </Accordion>
                            
                        </Container>                      
                        
                        <Container >
                            <Row>
                                <Col xs={12}>
                                    <Link to={"/"+language+"/tire-size-correspondence/"}><div className='btn btn-back' dangerouslySetInnerHTML={{__html: structure.tire_size_corrispondence.risultati_back}}></div></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>  
                    
                    <Scroller id='tire-size-corrispondence' />
                </div>
            );
        }else{
            return(<div>
            <div className='main bacground-grey'>
                <div className="main-sep"></div>
                <Container>
                    <Row>
                        <Col>
                            <Skeleton height={30}/><Skeleton height={30} /><Skeleton height={30} /><Skeleton height={30} /><Skeleton height={30} />
                        </Col>
                    </Row>
                </Container>
                </div></div>);
        }
    }
    
}
 