import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Switch, Link, HashRouter, useLocation  } from 'react-router-dom';
import MainContainer from './MainContainer';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
   
    if (window.cordova) {
      
      
        return (
            <HashRouter basename="./" >
             <MainContainer />
            </HashRouter >
            );
        } else {

            return (
            <BrowserRouter basename="/" >
              <MainContainer />
            </BrowserRouter >
        );
    }
}

export default App;

