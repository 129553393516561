import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';

export default class Home extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
    }
    componentDidMount()
    {
        
    }
    reload(e)
    {
       window.location.reload(false);

    }
    render() {
       
       
            return (
                    <Container>
                    <Row>
                        <Col>
                            <div className='error-connection'>
                                <p>Trelleborg Tire Book</p>
                                <h2>Error connection</h2>
                                <p>This app requires an active internet connection to works</p>
                                <p>Check your connection and click on Retry button</p>
                                
                                <div className="btn" onClick={this.reload}>Retry</div>
                            </div>
                        </Col>
                    </Row>
                    </Container>
            );

    }
    
}
