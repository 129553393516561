import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import SizeList from './../helpers/SizeList'
import Scroller from './../helpers/Scroller'
import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab, InputGroup,FormControl, Modal   } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';


export default class Contacts extends React.Component 
{
    static contextType = AppContext;

    constructor(props) 
    {
        super(props);
        this.state = {show: false};
    }
    
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            level:20,
            resetPage:true,
        };
       
        const { setData } = this.context;
        setData(params);
        
    }
   
    handleClose()
    {
        this.setState({ show: false })
    }
    sendEmail()
    {
         const { structure } = this.context;
        this.setState({ show: false })
        location.href =(structure.contacts.email_link.url)
    }
    render() 
    {
        const { structure, loading } = this.context;
        
        
        if (Object.keys(structure).length !=0 )
        {
            return (
              <div>
                    <div className='main contacts' id='contacts'>
                        <div className="main-sep"></div>
                        <div className='m-f'>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.contacts.titolo}}></div>
                                    </div>
                                </Col>
                                
                                <Col xs={12} onClick={() => this.setState({ show: true })}>
                                    <div  className="lista-grigia">
                                       { structure.contacts.email_immagine && (
                                        <img src={structure.contacts.email_immagine} className='icona' />)
                                        }{structure.contacts.email_link.title}
                                    </div>
                                </Col>
                                
                                {
                                    structure.contacts.lista.map((item, i) => 
                                    {     
                                        return (
                                                <Col xs={12} key={'lista-contacts-'+i} >
                                                    <a href={item.link.url} target={item.link.target} >
                                                        <div className="lista-grigia">
                                                        { item.immagine && (
                                                        <img src={item.immagine} className='icona' />)
                                                        }{item.link.title}
                                                        </div>
                                                    </a>
                                                </Col>
                                        )

                                    })
                                }
                                
                            </Row>
                        </Container>
                        </div>
                    </div>
                            
                            
                    
                    <Modal
                        show={this.state.show}
                        onHide={this.handleClose.bind(this)}
                        >
                        <Modal.Header >
                          <Modal.Title>
                            <h5>{structure.contacts.email_link.title}</h5>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body  dangerouslySetInnerHTML={{__html: structure.contacts.email_modal_descrizione}}>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>
                              <Button variant="" onClick={this.handleClose.bind(this)}>{structure.contacts.email_modal_cancel}</Button>
                            </div>
                            <div>
                              <Button variant="" onClick={this.sendEmail.bind(this)}>{structure.contacts.email_modal_ok}</Button>
                            </div>
                        </Modal.Footer>
                    </Modal>         
                    <Scroller id='contacts' />
              </div>
                      
            );
        }else{
            return (
                    
                <div>
                    <div className='main technical-data'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="titolo">
                                        <Skeleton />
                                    </div>

                                    
                                    <div className="result">
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
              </div>
                    
                    
                    
                    );
            
        }
            
    }
    
}

