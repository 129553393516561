import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';

export default class Home extends React.Component 
{
    constructor(props) 
    {
        super(props);
        
    }
    componentDidMount()
    {
        
    }
    gotoHome(e)
    {
       window.location.replace("/");

    }
    render() {
       
       
            return (
                    <div className='m-f'>
                    <Container>
                    <Row>
                        <Col>
                        
                            <div className='error-connection'>
                                <p>Trelleborg Tire Book</p>
                                <h2>404</h2>
                                <p>Page not found</p>
                                
                                <div className="btn" onClick={this.gotoHome}>Home</div>
                            </div>
                            
                        </Col>
                    </Row>
                    </Container>
                   
                    </div>
            );

    }
    
}
