import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import SizeList from './../helpers/SizeList'
import Scroller from './../helpers/Scroller'
import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab, InputGroup,FormControl, Modal   } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';


export default class Apps extends React.Component 
{
    static contextType = AppContext;

    constructor(props) 
    {
        super(props);
    }
    
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            level:20,
            resetPage:true,
        };
       
        const { setData } = this.context;
        setData(params);
        
    }
   
  
    render() 
    {
        const { structure, loading } = this.context;
        
        
        if (Object.keys(structure).length !=0 )
        {
            return (
              <div>
                    <div className='main apps' id='apps'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.apps.titolo}}></div>
                                    </div>
                                </Col>
                                
                               
                                
                                {
                                    structure.apps.lista.map((item, i) => 
                                    {     
                                        return (
                                                <Col  sm={6} md={4} key={'lista-contacts-'+i} >
                                                    
                                                        <div className="lista-grigia text-center ">
                                                         <div className="app-content">
                                                        { item.immagine && (
                                                                   <img src={item.immagine} className='app-icona img-fluid' />)
                                                                   }
                                                                   <div className="apps-titolo mt-3">{item.titolo}</div>
                                                                    <a href={item.ios_link.url} target={item.ios_link.target} >
                                                                    { item.ios_icona && (
                                                                        <div className="limita-icona">
                                                                            <img src={item.ios_icona} className='   img-fluid' />
                                                                        </div>)
                                                                    }
                                                                    </a>
                                                                    <a href={item.android_link.url} target={item.android_link.target} >
                                                                        { item.android_icona && (
                                                                        <div className="limita-icona">
                                                                            <img src={item.android_icona} className='    img-fluid' />
                                                                        </div>)
                                                                        }
                                                                    </a>
                                                            </div>    
                                                        </div>
                                                       
                                                        
                                                        

                                                    
                                                    
                                                </Col>
                                        )

                                    })
                                }
                                
                            </Row>
                        </Container>
                    </div>
                            
                            
                  
                    <Scroller id='apps' />
              </div>
                      
            );
        }else{
            return (
                    
                <div>
                    <div className='main technical-data'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="titolo">
                                        <Skeleton />
                                    </div>

                                    
                                    <div className="result">
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                        <Skeleton height={100} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
              </div>
                    
                    
                    
                    );
            
        }
            
    }
    
}

