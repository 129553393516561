import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import ProductList from './../helpers/ProductList'
import Scroller from './../helpers/Scroller'
import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab, InputGroup,FormControl   } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

export default class ProductSearch extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.product_search_slug=new Array();
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            
            level:20,
        };
        const { setData,setSearchProductKey,scroll_to } = this.context;
        setData(params);
        
    }
  
    handleClick() 
    {
        //const { history,searchKey,language } = this.context;
        //history.push("/"+language+"/technical-data/"+searchKey+"/");
    }
    onChangeKeySearch(e)
    {
        if(e.charCode==13)
        {
                e.target.blur();
//            this.handleClick();
        }else{
            const { setSearchProductKey} = this.context;
            setSearchProductKey(e.target.value);
        }
    }
    get_product_search(parent ,searchProductKey, product_search)
    {
        const _this=this;
        if (parent)
        {
            parent.forEach(function(categoria)
            {
                categoria.products.forEach(function(product)
                {
                    if (!_this.product_search_slug.includes(product.slug))
                    {
                        if (
                            !searchProductKey || 
                            (product.titolo && product.titolo.toLowerCase().indexOf(searchProductKey.toLowerCase())!=-1) ||
                            (product.tread_pattern && product.tread_pattern.toLowerCase().indexOf(searchProductKey.toLowerCase())!=-1)
                        )
                        {
                           product_search.push(product)
                            _this.product_search_slug.push(product.slug)
                        }
                    }
                })
                
                product_search=_this.get_product_search(categoria.child,searchProductKey, product_search );
            })
        }
        return (product_search);
    }
    render() 
    {
        const { structure, loading,searchProductKey,language } = this.context;
        if (Object.keys(structure).length !=0 )
        {
            this.product_search_slug=new Array();
            let product_search=this.get_product_search(structure.product_range.categorie,searchProductKey,  new Array());
           
            return (
              <div  >
                    <div className='main technical-data' id='product-search'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.product_search.titolo}}></div>
                                    </div>
                                </Col>
                                
                                <Col xs={12}>    
                                    <div className="search-box d-flex align-items-center"> 
                                        <InputGroup className="mb-3">

                                        <FormControl
                                            placeholder={structure.product_search.search_placeholder}
                                            value={searchProductKey}
                                            aria-label={structure.product_search.search_placeholder}
                                            aria-describedby="basic-addon2"
                                            onKeyPress={this.onChangeKeySearch.bind(this)}
                                            onChange={this.onChangeKeySearch.bind(this)}
                                        />
                                        <InputGroup.Append>
                                                <Button variant="white" onClick={this.handleClick.bind(this)} ><img src={structure.product_search.search_icona} className="img-fluid search-icon" /></Button>
                                        </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                    <div className="result">
                                        <ProductList products={product_search} /><div  className='m-f'></div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    
                    <Scroller id='product-search'/>
              </div>
                      
            );
        }else{
            return (
                    
                <div>
                    <div className='main technical-data'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="titolo">
                                        <Skeleton />
                                    </div>

                                    <div style={{marginTop: '14px'}}> 
                                    <Skeleton  height={30}/>
                                        <Skeleton  height={40}/>
                                        
                                    </div>
                                    <div className="result">
                                        <Skeleton width={250} />
                                        <Skeleton width={200} />
                                        <Skeleton width={300} />
                                        <Skeleton width={220} />
                                        <Skeleton width={250} />
                                        <Skeleton width={200} />
                                        <Skeleton width={220} />
                                        <Skeleton width={210} />
                                        <Skeleton width={210} />
                                        <Skeleton width={300} />
                                        <Skeleton width={250} />
                                        <Skeleton width={200} />
                                        <Skeleton width={300} />
                                        <Skeleton width={220} />
                                        <Skeleton width={210} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
              </div>
                    
                    
                    
                    );
            
        }
            
    }
    
}

