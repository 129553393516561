import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import Scroller from './../helpers/Scroller'
import SizeFilter from './../helpers/SizeFilter'
import SizeParams from './../helpers/SizeParams'

import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab, InputGroup,FormControl, Form  } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from './../helpers/Breadcrumb';



export default class MechanicalLeadCalculator extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.state = {};
        this.onCompleteRearFilter = this.onCompleteRearFilter.bind(this);
        this.onUncompleteRearFilter = this.onUncompleteRearFilter.bind(this);
        this.onCompleteFrontFilter = this.onCompleteFrontFilter.bind(this);
        this.onUncompleteFrontFilter = this.onUncompleteFrontFilter.bind(this);
        this.onSetAxelRatio=this.onSetAxelRatio.bind(this);
        this.get_result=this.get_result.bind(this);
        this.reset_input=this.reset_input.bind(this);
        this.onKeyAxelRatio=this.onKeyAxelRatio.bind(this);
        this.size_rear= React.createRef();
        this.size_front= React.createRef();
        this.axle_ratio=React.createRef();
    }
    
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            size_list:1,
            level:20,
            
        };
        const { setData } = this.context;
        setData(params);
        
    }
    componentWillUnmount()
    {
        const { resetSize} = this.context;
        resetSize();
    }
    onCompleteRearFilter(size)
    {
       this.setState({rear_size:size}, () => {this.checkCompleteInput()});
    }
    onUncompleteRearFilter()
    {
        this.setState({rear_size:null}, () => {this.checkCompleteInput()});
    }
    onCompleteFrontFilter(size)
    {
       this.setState({front_size:size}, () => {this.checkCompleteInput()});
    }
    onUncompleteFrontFilter()
    {
       this.setState({front_size:null}, () => {this.checkCompleteInput()});
    }
    onSetAxelRatio(e)
    {
        this.setState({axle_ratio:e.target.value}, () => {this.checkCompleteInput()});
    }
    onKeyAxelRatio(e)
    {
        if(e.charCode==13)
        {
            e.target.blur();
            this.checkCompleteInput()
        }
    }
    checkCompleteInput()
    {
        const { mechanical_lead_calculator_reset_result } = this.context;
        mechanical_lead_calculator_reset_result();
        if (this.state.front_size && this.state.rear_size && this.state.axle_ratio)
        {
            this.setState({inputComplete:true})
        }else{
            this.setState({inputComplete:false})
            
        }
    }
    reset_input()
    {
        const { mechanical_lead_calculator_reset_result } = this.context;
        mechanical_lead_calculator_reset_result();
       this.size_rear.current.reset_size();
       this.size_front.current.reset_size();
       this.axle_ratio.current.value='';
       setTimeout(function()
        {
            if ( document.getElementById('mechanical-lead-calculator'))
            {
                document.getElementById('mechanical-lead-calculator').scroll(0,0);
            }
        },100);
    }
    get_result()
    {
       
        const params={
            front:this.state.front_size,
            rear:this.state.rear_size,
            axle_ratio:this.state.axle_ratio,
            language:this.props.match.params.language,
        };
        const { mechanical_lead_calculator_get_result } = this.context;
        mechanical_lead_calculator_get_result(params);
    }
    render() 
    {
        const { structure, loading, language, size_list,lastTSCOpen,mechanical_lead_calculator_result, mechanical_lead_calculator_loading_result } = this.context;
      
       if (size_list.length !=0 )
        {
            let auto_key='';
            let value;
            let link='';
            
            let lista_parametri=new Array();
            let auto_container_class='disabled';
            if (this.state.inputComplete)
            {
                auto_container_class='';
            }
            if (mechanical_lead_calculator_loading_result)
            {
                auto_container_class+=' loading';
                 
            }
            let result='';
            
            if (mechanical_lead_calculator_result && mechanical_lead_calculator_result.success==1 )
            {
                setTimeout(function()
                {
                    if ( document.getElementById('mechanical-lead-calculator'))
                    {
                        document.getElementById('mechanical-lead-calculator').scroll(0, 999999);
                    }
                },100);
            }
            return (
                <div className='m-f'>
                    <div className='main mechanical-lead-calculator'  id="mechanical-lead-calculator">
                    <Breadcrumb current={structure.mechanical_lead_calculator.titolo}></Breadcrumb>
                        <div className="main-sep"></div>
                        <Container >
                            <Row>
                                <Col xs={12}>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.mechanical_lead_calculator.titolo}}></div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container >
                            <Row>
                                <Col xs={12}>
                                    <div className="rear-titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.mechanical_lead_calculator.rear_titolo}}></div>
                                    </div>
                                </Col>
                            </Row>
                        
                            <SizeFilter  ref={this.size_rear} containerId='mechanical-lead-calculator' lastTSCOpen='' onCompleted={this.onCompleteRearFilter} onUncompleted={this.onUncompleteRearFilter}  select_lista={structure.mechanical_lead_calculator.rear_select_lista} />
                        </Container>
                        
                        <Container >
                            <Row>
                                <Col xs={12}>
                                    <div className="front-titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.mechanical_lead_calculator.front_titolo}}></div>
                                    </div>
                                </Col>
                            </Row>
                        
                            <SizeFilter  ref={this.size_front}  containerId='mechanical-lead-calculator'  lastTSCOpen='' onCompleted={this.onCompleteFrontFilter} onUncompleted={this.onUncompleteFrontFilter}  select_lista={structure.mechanical_lead_calculator.front_select_lista} />
                        </Container>
                        <Container >
                            <Row>
                                <Col xs={12}>
                                    <div className="axle-titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.mechanical_lead_calculator.axle_ratio_titolo}}></div>
                                    </div>
                                   
                                     <InputGroup className="mb-3">

                                        <FormControl
                                            placeholder={structure.mechanical_lead_calculator.axle_ratio_placeholder}
                                            aria-label={structure.mechanical_lead_calculator.axle_ratio_placeholder}
                                            aria-describedby="basic-addon2"
                                            onKeyPress={this.onKeyAxelRatio.bind(this)}
                                            onChange={this.onSetAxelRatio.bind(this)}
                                             ref={this.axle_ratio}
                                        />
                                        
                                    </InputGroup>
                                    
                                    
                                </Col>
                            </Row>       
                        </Container>
                        {  mechanical_lead_calculator_result && mechanical_lead_calculator_result.success==0 && 
                                <Container className='error' >
                                    <Row>
                                        <Col xs={12} >
                                        <div dangerouslySetInnerHTML={{__html: mechanical_lead_calculator_result.error}}></div>
                                        </Col>
                                    </Row>
                                </Container>
                        }
                        <Container className={'btn-container '+auto_container_class } >
                            <Row>
                                <Col xs={12} >
                                <div className= 'btn btn-blue' onClick={this.get_result} dangerouslySetInnerHTML={{__html: structure.mechanical_lead_calculator.btn_label}}></div>
                                </Col>
                            </Row>
                        </Container>
                        
                        {  mechanical_lead_calculator_result && mechanical_lead_calculator_result.success==1 && 
                                <Container >
                                    <Row>
                                        <Col xs={12} >
                                        <div className="result-titolo" dangerouslySetInnerHTML={{__html: structure.mechanical_lead_calculator.result_titolo}}></div>
                                        <div   className='result' dangerouslySetInnerHTML={{__html: mechanical_lead_calculator_result.result}}></div>
                                        </Col>
                                    </Row>
                                </Container>
                        }
                         <Container className={'btn-container ' } >
                            <Row>
                                <Col xs={12} >
                                <div className= 'btn btn-back' onClick={this.reset_input} dangerouslySetInnerHTML={{__html: structure.mechanical_lead_calculator.reset}}></div>
                                </Col>
                            </Row>
                        </Container>
                    </div>  
                    <Scroller id='mechanical-lead-calculator' />
                </div>
            );
        }else{
       
            return(<div>
            <div className='main bacground-grey'>
                <div className="main-sep"></div>
                <Container>
                    <Row>
                        <Col>
                            <Skeleton height={30}/><br/><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                            <Skeleton height={15} /><Skeleton height={15} /><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                            <Skeleton height={15} /><Skeleton height={30} /><br/>
                           
                            <Skeleton height={30}/>
                        </Col>
                    </Row>
                </Container>
                </div></div>);
        }
    }
    
}
 