import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Skeleton from 'react-loading-skeleton';



export default class Size extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.goBack = this.goBack.bind(this);
        
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            size:{id:this.props.match.params.id},
            level:40,
        };
        const { setData,setLastSizeOpen } = this.context;
        setData(params);
        
        setLastSizeOpen(this.props.match.params.id);
    }
    componentWillUnmount()
    {
        const { resetSize} = this.context;
        resetSize();
    }
    goBack()
    {
        
        this.props.history.goBack();
    }
    render() 
    {
        const { loading, structure ,size} = this.context;
        if ( Object.keys(size).length  )
        {
            return (
                <div>
                    <div className=' main background-grey' id={'size-'+this.props.match.params.id}>
                        <div className="main-sep"></div>
                        <Container >
                            <Row>
                                <Col className="size-col">
                                    {size.html && (
                                        <PinchZoomPan className=' ' zoomButtons={false}	  maxScale={3} >
                                            <div className=' size-sheet-container' dangerouslySetInnerHTML={{__html:size.html }} />
                                        </PinchZoomPan>
                             
                                    )}
                                    {size.error && (
                                            <div className='size-error'><h1 >{size.error}</h1></div>
                                    )}
                                    <div  className='h-f'></div>          
                                </Col>
                            </Row>
                        </Container>
                        <div className='close-fixed-bottom'>
                            <Container>
                                <Row >
                                    <Col>
                                        <div className='btn btn-back' onClick={this.goBack}> {structure.technical_data.symbol_back}</div>
                                         
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>  
                </div>
            );
        }else{
            return(<div>
            <div className='main bacground-grey'>
                <div className="main-sep"></div>
                <Container>
                    <Row>
                        <Col>
                            <Skeleton/><Skeleton  /><Skeleton height={250} /><Skeleton/><Skeleton/><Skeleton width={250}/>
                        </Col>
                    </Row>
                </Container>
                </div></div>);
        }
    }
    
}
 
 /*
  * 

  */