import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import Scroller from './../helpers/Scroller'
import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab } from 'react-bootstrap';
import ProductList from './../helpers/ProductList';
import Breadcrumb from './../helpers/Breadcrumb';

export default class ProductRange extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            level:20,
        };
        const { setData } = this.context;
        setData(params);
    }
    changeAccordion(e)
    {
        const { history } = this.context;
        history.push("/"+this.props.match.params.language+"/product-range/"+e+"/");
        
    }
    render() {
       
        const { structure, language } = this.context;
        return (
           
          <div className=''>
  
                {structure.product_range && (
                        
                <div className='main product-range' id='product-range'>
                    <Breadcrumb current={structure.product_range.titolo}></Breadcrumb>
                    <div className="main-sep"></div>

                    <Container>
                        <Row>
                        
                            <Col xs={12}>
                                <div className="titolo ">
                                    <div dangerouslySetInnerHTML={{__html: structure.product_range.titolo}}></div>
                                </div>
                                <Accordion defaultActiveKey={this.props.match.params.first }  activeKey={this.props.match.params.first }>
                                { structure.product_range.categorie.map((voce, i) => { 
                                    {i=i+1;}
                                    
                                    return(
                                    <div key={i}>
                                        <div>
                                            <Accordion.Toggle   onClick={() => this.changeAccordion(voce.slug)}  className={'btn ' + (this.props.match.params.first == voce.slug ? 'active' : '' )} variant="link" eventKey={voce.slug} dangerouslySetInnerHTML={{__html: voce.titolo}}>
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey={voce.slug}>
                                            <div>
                                                <ProductRangeMenuContent content={voce} second={this.props.match.params.second} />
                                                <ProductList  products={voce.products} />
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                    )
                                    })} 
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                    <div className='m-f'>
                    </div>
                    <Scroller id='product-range' />
                </div>
                )}
          </div>
       
        );
    }
    
}

class ProductRangeMenuContent extends React.Component 
{
    static contextType = AppContext;
    
    changeTab(e)
    {
        const {  language } = this.context;
        const { history } = this.context;
        history.push("/"+language + "/product-range/"+this.props.content.slug+"/"+e+"/");
       
    }
    
    render() {
        
        const { structure, language } = this.context;
        
        let activeKey=''
        if ( this.props.content.child.length )
        {
            activeKey=this.props.content.child[0].slug;
        }
        if (this.props.second)
        {
            activeKey=this.props.second
        }
        return (
            <div>
  
                {  this.props.content.child.length!=0 && (
                     
                    <Tabs defaultActiveKey={activeKey}  activeKey={activeKey}  onSelect ={this.changeTab.bind(this)} >
                        {this.props.content.child.map((voce, i) => { 
                            return (
                                <Tab key={'range-menu-tab-'+voce.slug} eventKey={voce.slug} title={voce.titolo}  >
                                    <ProductList  products={voce.products} />
                                </Tab>
                            )
                        })}
                    </Tabs>
                )}
            </div>
        )
    }
}
