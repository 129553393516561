import React from 'react';
import AppContext from './../helpers/AppContext';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';

export default class SizeParams extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
    }

    render() {
       
        return(
           <Container className={'size-params-container '+this.props.containerClass } >
            {
            this.props.titolo && (
                <Row>
                    <Col xs={12} className='size-params-titolo' dangerouslySetInnerHTML={{__html: this.props.titolo }}>
                    </Col>
                </Row>
            )}
                {

                    this.props.lista.map((item, i) => 
                    {    
                        return (
                            <Row key={'sizeParams'+item.titolo+i} className="align-items-center  size-params-row" >
                                <Col xs={6} sm={3} lg={2} dangerouslySetInnerHTML={{__html: item.titolo}} ></Col>
                                <Col xs={6} sm={9}  lg={10}  dangerouslySetInnerHTML={{__html:item.value}} ></Col>
                            </Row>

                        ) 
                    })
                }
            </Container>
        );
      /*
        const { language, structure, lastSizeOpen} = this.context;
         
        let size_list=this.props.list;
        if(size_list && size_list.length)
        {
            return (
                <div className="size-list">
                { size_list.map((voce, i) => {
                  
                    return <div className={lastSizeOpen==voce.ID_code ? "size-link active" : "size-link"} key={"size-list-"+i}><Link to={'/'+language+'/size/'+voce.ID_code+'/'}>{voce.third}  {voce.description}</Link></div>
                })}
                
                </div>
                
            );
   
            
        }else{
            return (
                <div className="size-list">
                    <div className="no-result" dangerouslySetInnerHTML={{__html: structure.technical_data.no_result}}></div>
                </div>
            );
        }
             * 
       */
    }
    
}
