import React from 'react';
import AppContext from './../helpers/AppContext';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';

export default class SizeList extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
    }

    render() {
      
        const { language, structure, lastSizeOpen} = this.context;
         
        let size_list=this.props.list;
        if(size_list && size_list.length)
        {
            return (
                <div className="size-list">
                { size_list.map((voce, i) => {
                  
                    return <div className={lastSizeOpen==voce.ID_code ? "size-link active" : "size-link"} key={"size-list-"+i}><Link to={'/'+language+'/size/'+voce.ID_code+'/'}>{voce.third}  {voce.description}</Link></div>
                })}
                
                </div>
                
            );
   
            
        }else{
            return (
                <div className="size-list">
                    <div className="no-result" dangerouslySetInnerHTML={{__html: structure.technical_data.no_result}}></div>
                </div>
            );
        }
    }
    
}
