import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab } from 'react-bootstrap';
import Scroller from './../helpers/Scroller'
import Breadcrumb from './../helpers/Breadcrumb';

export default class TechincalInformations extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            level:20,
        };
        const { setData} = this.context;
        setData(params);
        
        
    }
    changeAccordion(e)
    {
        const { history } = this.context;
        history.push("/"+this.props.match.params.language+"/technical-informations/"+e+"/");
        
    }
    render() {
       
        const { structure } = this.context;
        return (
           
          <div>
  
                {structure.technical_informations && (
                        
                <div className='main technical-informations' id="technical-informations">
                    <Breadcrumb current={structure.technical_informations.titolo}></Breadcrumb>
                    <div className="main-sep"></div>
                    <Container>
                        <Row>
                            <Col>
                                <div className="titolo">
                                    <div dangerouslySetInnerHTML={{__html: structure.technical_informations.titolo}}></div>
                                </div>
                                
                                <Accordion defaultActiveKey={this.props.match.params.first }  activeKey={this.props.match.params.first }>
                                { structure.technical_informations.pagine.map((voce, i) => { 
                                    {i=i+1;}
                                    
                                    return(
                                    <div key={i}>
                                        <div>
                                            <Accordion.Toggle   className='btn'  onClick={() => this.changeAccordion(voce.slug)}   variant="link" eventKey={voce.slug} dangerouslySetInnerHTML={{__html: voce.titolo}}>
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey={voce.slug}>
                                            <div>
                                            <TechincalInformationsMenuContent content={voce} parent={this.props.match.params.first} />
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                    )
                                    })} 
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                     <div  className='h-f'></div>
                </div>
                )}
       
            <Scroller id="technical-informations" />
          </div>
       
        );
    }
    
}


class TechincalInformationsMenuContent extends React.Component 
{
    static contextType = AppContext;
    
   
    
    render() {
        
        const { structure, language,lastTechnicalInformationsOpen } = this.context;
        return (
            <div>
                {this.props.content.child.map((voce, i) => { 
   
                    return (
                        <Link key={'technical-informations-'+voce.slug} to={"/"+language+"/technical-informations-detail/"+this.props.parent+"/"+voce.slug}  >
                        <div className={lastTechnicalInformationsOpen==voce.slug ? "btn-white active" : "btn-white"} dangerouslySetInnerHTML={{__html: voce.titolo}}>
                        
                        </div>
                        </Link>
                    )
            
                })}
            </div>
        )
    }
}
