import React from 'react';
import AppContext from './../helpers/AppContext';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';

export default class ProductList extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
    }
    componentDidMount()
    {
    }
    render() {
       
        const { language,structure,lastProductOpen } = this.context;
       
        let products=this.props.products;
        if(products.length)
        {
            return (
                <div className="product-list">
                { products.map((voce, i) => {
                    return <div className={lastProductOpen==voce.slug ? "product-link active" : "product-link"} key={"list-product-"+voce.slug}><Link to={'/'+language+'/product/'+voce.slug+'/'} dangerouslySetInnerHTML={{__html: voce.titolo}} ></Link></div>
                })}         
                </div>
            );
        }else{
            return (
                ''
            );
        }
    }
    
}
