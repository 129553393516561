import React, { Component } from 'react';
import axios from 'axios';
import ErrorConnection from '../pages/ErrorConnection';
import Error404 from '../pages/Error404';

const AppContext = React.createContext()

class AppProvider extends Component 
{
    constructor(props)
    {
        super(props);
        this.prevLanguage='';
        this.prevLevel=0;
        this.lastSizeOpen='';
        this.lastTSCOpen='';
        this.lastCCalcOpen='';
        
        this.lastProductOpen='';
        this.lastTechnicalInformationsOpen='';
        this.memScroll=new Array();
        this.resetPage=false;
    }
    
    state = {
        history:this.props.history,
        languages:[],
        languages_structure:{},
        structure:{},
        size_list:[],
        size:{},
        product:{},
        loading:false,
        reverseClass:'',
        searchProductKey:'',
        searchSizeKey:'',
        time_data:0,
        error_connection:false,
        ccalculator_size:[],
    }
    mechanical_lead_calculator_get_result=(params)=>
    {
        this.setState({ mechanical_lead_calculator_loading_result:true});
        var baseUrl=this.props.baseUrl+params.language+'/';
        var send={
            requests:params,
        }
        axios.post(baseUrl+'api/mechanical-lead-calculator-get-result', send)
        .then(response => {
            var mechanical_lead_calculator_result={success: response.data.success, result:response.data.result, error:response.data.error};
        
            this.setState({mechanical_lead_calculator_result:mechanical_lead_calculator_result, mechanical_lead_calculator_loading_result:false});
        }).catch(error =>  {
           }
        )
    }
    mechanical_lead_calculator_reset_result=(params)=>
    {
        this.setState({mechanical_lead_calculator_result:null, mechanical_lead_calculator_loading_result:false});
    }
    setData=(params)=>
    {
        if (params.resetPage)
        {
            this.resetPage=true;
        }
        
        if (!this.state.loading)
        {
            var reset=false;
            if (!params || !params.language)
            {
                var  language='it';
                reset=true;
            }else{
                var language=params.language;

                if (this.prevLanguage!=language)
                {
                    reset=true;
                }
                this.prevLanguage=language;
            }
            
            var d = new Date();
            if (d.getTime() > this.state.time_data+4*60*60*1000)
            {
                reset=true;
            }
            if (reset)
            {
                this.setState({ 
                    structure:{},
                    size_list:[],
                    size:{},
                    product:{},
                    ccalculator_size:[],
                });
            }
            var baseUrl=this.props.baseUrl+language+'/';
            var requests=[];

            if (!this.state.languages.length)
            {
                requests.push({status: 'languages'});
            }
            if (params && params.structure  && params.language && (!Object.keys(this.state.structure).length || reset))
            {
                requests.push({status: 'structure', version:2});
            }
            if (params && params.size_list && (!Object.keys(this.state.size_list).length))
            {
                requests.push({status: 'size_list'});
            }
            if (params && params.product && params.product.slug)
            {
                requests.push({status: 'product', slug: params.product.slug});
            }
            if (params && params.size && params.size.id)
            {
                requests.push({status: 'size', id: params.size.id});
            }
            if (params && params.ccalculator_size && params.ccalculator_size)
            {
                requests.push({status: 'ccalculator_size', id: params.ccalculator_size});
            }
            if (requests.length)
            {
                let _this=this;
                this.setState({loading:true});
                var send={
                    requests:requests,

                }
                axios.post(baseUrl+'api/get-data/', send)
                .then(response => {
                    response.data.loading=false;
                    response.data.language=language;
                    var d = new Date();
                    response.data.time_data=d.getTime();
                    this.setState(response.data)
                }).catch(error =>  {
                        _this.setState({error_connection:true})
                    }
                )
            }        
        }
        if (this.prevLevel> params.level)
        {
            this.setState({reverseClass:'reverse-animation'});
            
        }else{
            this.setState({reverseClass:''});
        }
        
        this.prevLevel=params.level;
    }
    
    resetSize=()=>
    {
        this.setState({size:{}});
    }
    resetCCalculatorResult=()=>
    {
        this.setState({ccalculator_size:[]});
    }
    resetProduct=()=>
    {
        this.setState({product:{}});
    }
    
    setSearchProductKey=(search)=>
    {
        this.setState({searchProductKey:search});
    }
    setSearchSizeKey=(search)=>
    {
        this.setState({searchSizeKey:search});
    }
           
    setLastTSCOpen=(size)=>
    {
        this.lastTSCOpen=size;
    }
    setLastCCalcOpen=(size)=>
    {
        this.lastCCalcOpen=size;
    }
    setLastSizeOpen=(size)=>
    {
        this.lastSizeOpen=size;
    }
    setLastProductOpen=(product)=>
    {
        this.lastProductOpen=product;
        
    }
    setLastTechnicalInformationsOpen=(technical_informations)=>
    {
        this.lastTechnicalInformationsOpen=technical_informations;
    }
    
    setMemScroll=(slug, scroll)=>
    {
        
        if (this.resetPage)
        {
            this.memScroll=new Array();
            this.lastSizeOpen='';
            this.lastTSCOpen='';
            this.lastProductOpen='';
            this.lastCCalcOpen='';
            this.lastTechnicalInformationsOpen='';
            this.resetPage=false;
            this.state.searchProductKey='';
            this.state.searchSizeKey='';
            this.state.mechanical_lead_calculator_result=null;
            this.state.mechanical_lead_calculator_loading_result=false;
        }else{
            this.memScroll[slug]=scroll;
        }
    }
    
    
    render() {
        
        const { children , scroll_to} = this.props
        const { language,  languages ,languages_structure, structure , product, history ,loading ,size , level,searchProductKey,searchSizeKey, size_list,error_connection, mechanical_lead_calculator_result, mechanical_lead_calculator_loading_result,ccalculator_size} = this.state
        const { setData, mechanical_lead_calculator_get_result,mechanical_lead_calculator_reset_result , resetSize,resetCCalculatorResult, resetProduct,setSearchProductKey,setSearchSizeKey, setLastProductOpen,lastProductOpen,setLastSizeOpen,lastSizeOpen,setLastTSCOpen,setLastCCalcOpen,lastCCalcOpen,lastTSCOpen,setLastTechnicalInformationsOpen, lastTechnicalInformationsOpen, setMemScroll, memScroll} = this;
        
        if(!error_connection)
        {
            return (
              <AppContext.Provider
                value={{
                    language, languages ,languages_structure, structure,  product, loading,size,searchProductKey,searchSizeKey,size_list,scroll_to,lastSizeOpen,lastTSCOpen,lastCCalcOpen,ccalculator_size,lastProductOpen,setMemScroll ,memScroll,mechanical_lead_calculator_result,mechanical_lead_calculator_loading_result,
                    setData,mechanical_lead_calculator_get_result,mechanical_lead_calculator_reset_result, history, resetSize,resetCCalculatorResult, resetProduct,setSearchProductKey,setSearchSizeKey,setLastSizeOpen,setLastTSCOpen,setLastCCalcOpen,setLastProductOpen,setLastTechnicalInformationsOpen, lastTechnicalInformationsOpen
                }}
              >
                    <Loader loading={this.state.loading} reverse={this.state.reverseClass} language={language}>
                        {children}
                    </Loader>
                </AppContext.Provider>
            )
        }else{
            if (window.cordova) {
                return (<ErrorConnection />);
            }else{
                return (<Error404 />);
            }    
        }
    }
}

export default AppContext

export { AppProvider }


class Loader extends React.Component {
    render() 
    {
        const { children, loading , reverse, language} = this.props;
        return <div className={reverse +" "+language} ><div className={loading ? ' loader loading' : '  loader loaded'}/>  {children}</div>;
    }
}