import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import Scroller from './../helpers/Scroller'
import SizeFilter from './../helpers/SizeFilter'
import SizeParams from './../helpers/SizeParams'
import { Container, Row, Col, Accordion, Card, Button, Tabs, Tab, InputGroup,FormControl, Form  } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from './../helpers/Breadcrumb';



export default class CCalculatorResult extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        this.state = {filtro_completato: false};
        this.onCompleteFilter = this.onCompleteFilter.bind(this);
        this.onUncompleteFilter = this.onUncompleteFilter.bind(this);
        this.sri=null;
        this.selectedSize=null;
    }
    componentDidMount()
    {
       
        const params={
            language:this.props.match.params.language,
            structure:1,
            size_list:1,
            level:30,
            ccalculator_size:this.props.match.params.size,
        };
        const { setData } = this.context;
        setData(params);
    
        
    }
    componentWillUnmount()
    {
        const { resetSize, resetCCalculatorResult} = this.context;
        resetSize();
        resetCCalculatorResult();
    }
    
    
    checkLastCCalcOpen()
    {
        
        const { structure, loading, language, size_list,setLastCCalcOpen } = this.context;
        if (size_list.length)
        {
            size_list.map((key, i) => 
            {
                if(key.ID_code==this.props.match.params.size)
                {
                    setLastCCalcOpen(key);
                    this.selectedSize=key;
                }
            })
        }
        
    }
    onCompleteFilter(size)
    {
        this.setState({size:size, filtro_completato:true})
    }
    onUncompleteFilter()
    {
        
        this.setState({filtro_completato:false})
        
    }
    render() 
    {
        const { structure, loading, language, size_list,lastCCalcOpen, ccalculator_size } = this.context;
        if (ccalculator_size.length !=0 )
        {
            if (!lastCCalcOpen)
            {
                this.checkLastCCalcOpen();
            }else{
                this.selectedSize=lastCCalcOpen;
            }
            let key='';
            let lista_parametri=new Array();
            let lista=structure.ccalculator.select_lista.concat(structure.ccalculator.auto_lista)
            Object.keys(lista).map((index, i) => 
            {     
                key=lista[Object.keys(lista)[index]]
                lista_parametri.push({titolo:key.titolo,value:this.selectedSize[key.variabile]});
            });
            
            if (ccalculator_size.PermittedRims)  
            {
                lista_parametri.push({titolo:structure.ccalculator.permitted_rims_titolo,value:ccalculator_size.PermittedRims});
            }else{
                lista_parametri.push({titolo:structure.ccalculator.permitted_rims_titolo,value:"-"});
                
            }        
            let pressure_result
            let pressure_class
            if (this.state.filtro_completato )
            {
                pressure_result=this.state.size.pressure;
                pressure_class='result';
            }else{
                pressure_result=structure.ccalculator.pressure_placeholder;
                pressure_class='';
            }
            
            return (
                <div>
                    <div className='main ccalculator-result'  id="ccalculator-result">
                    <Breadcrumb current={structure.ccalculator.titolo} btn_link={"/"+language+"/tire-pressure-calculator/"} btn_label={structure.ccalculator.risultati_back}></Breadcrumb>
                        <div className="main-sep"></div>
                        <SizeParams titolo={structure.ccalculator.risultati_scheda_titolo} containerClass='' lista={lista_parametri} ></SizeParams>
                        <Container >
                            <SizeFilter containerId='ccalculator' lista_param={ccalculator_size.cclist} lastTSCOpen={null} onCompleted={this.onCompleteFilter} onUncompleted={this.onUncompleteFilter}  select_lista={structure.ccalculator.ccalculator_param_lista} />
                        </Container>
                        <div className="ccalculator-result-box container">
                        <Container className="ccalculator-result-container" >
                            <Row>
                                
                                    <Col xs={12} sm={3} lg={2} dangerouslySetInnerHTML={{__html: structure.ccalculator.pressure_titolo}} className=""></Col>
                                    <Col xs={12} sm={9}  lg={10}  dangerouslySetInnerHTML={{__html: pressure_result}} className={'ccalculator-pressure '+pressure_class} ></Col>
                                
                            </Row>
                        </Container>
                        </div>
                        <Container >
                            <Row>
                                <Col xs={12}>
                                    <Link to={"/"+language+"/tire-pressure-calculator/"}><div className='btn btn-back' dangerouslySetInnerHTML={{__html: structure.ccalculator.risultati_back}}></div></Link>
                                </Col>
                            </Row>
                        </Container>
                    </div>  
                    
                    <Scroller id='ccalculator' />
                </div>
            );
        }else{
            return(<div>
            <div className='main bacground-grey'>
                <div className="main-sep"></div>
                <Container>
                    <Row>
                        <Col>
                            <Skeleton height={30}/><Skeleton height={30} /><Skeleton height={30} /><Skeleton height={30} /><Skeleton height={30} />
                        </Col>
                    </Row>
                </Container>
                </div></div>);
        }
    }
    
}
 /*
  * 
  * 
                        <Container >
                            <Row>
                                <Col xs={12}>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.ccalculator.titolo}}></div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
    
  */