import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
export default class Home extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        
    }
    componentDidMount()
    {
        const params={
            language:this.props.match.params.language,
            structure:1,
            level:10,
            resetPage:true,
        };
        const { setData } = this.context;
        setData(params);
    }
    render() {
       
        const { structure } = this.context;
        if (Object.keys(structure).length !=0 )
        {
            return (

              <div>
                    {structure.main && (

                    <div className='main'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="titolo">
                                        <div dangerouslySetInnerHTML={{__html: structure.main.titolo}}></div>
                                    </div>
                                    <div className="descrizione">
                                        <div dangerouslySetInnerHTML={{__html: structure.main.descrizione}}></div>
                                    </div>
                                    <div className='m-f'>
                                        <div className="main-menu">
                                        {
                                            structure.main.menu.map((menu_voce, i) => 
                                            {     
                                                return (<Link  key={"main"+menu_voce.url} to={menu_voce.url} ><div className='menu-button btn'  key={'main-'+menu_voce.url}>{menu_voce.titolo}</div></Link>) 
                                            })

                                        }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    )}
              </div>

            );
        }else{
             return (

              <div>
                    <div className='main'>
                        <div className="main-sep"></div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className="titolo">
                                        <Skeleton height={40}/>
                                    </div>
                                    <div className="descrizione">
                                        <Skeleton/>
                                        <Skeleton/>
                                    </div>
                                    <div className="main-menu">
                                        <Skeleton height={40}/>
                                        <Skeleton height={40}/>
                                        <Skeleton height={40}/>
                                        <Skeleton height={40}/>
                                        <Skeleton height={40}/>
                                        
    
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
              </div>

            );
        }
    }
    
}
