import React from 'react';
import AppContext from './../helpers/AppContext';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

export default class Breadcrumb extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
    }

    render() {
      
        const { language, structure} = this.context;
         
      
        if(structure)
        {
            if (this.props.btn_link && this.props.btn_label)
            {
                return (
                        <div className="breadcrumb-section"> 
                            <Link to={this.props.btn_link} className="goto-index">&lt; {this.props.btn_label}</Link> 
                        </div>
                        );
            }else{
                return (
                        <div className="breadcrumb-section"> 
                            <Link to={'/'+language+'/'} className="goto-index">&lt; {structure.common.index}</Link> 
                        </div>
                );
            }
            
        }else{
            return (
                <div>                   
                </div>
            );
        }
    }
    
}


/*
 *  <span dangerouslySetInnerHTML={{__html: this.props.current}}></span>
 */