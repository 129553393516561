import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from './../helpers/AppContext'
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';

export default class Languages extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
       
    }
    componentDidMount()
    {
        const params={
            level:0,
            resetPage:true,
        };
        const { setData } = this.context;
        setData(params);
    }
    render() {
        const { languages,languages_structure } = this.context;
        return (
            <div>
                
                <div className='main languages'>
                <Container>
                    <Row>
                        <Col>
                            {languages_structure.languages && (
                                <div>
                                    <div className="trelleborg-logo-container">
                                        <img src={languages_structure.languages.trelleborg_logo}  className="img-fluid " />     
                                    </div>
                                    <div className="tirebook-logo-container">
                                        <img src={languages_structure.languages.tirebook_logo}  className="img-fluid " />     
                                    </div>
                                    <div className='m-f'>
                                        <LanguagesButton/>
                                    </div>
                                </div>
                            )}
                            <br/>
                            
                        </Col>
                    </Row>
                </Container>
                </div>

            </div>
        );
    }
    
}



class LanguagesButton extends React.Component 
{
    static contextType = AppContext;
    constructor(props) 
    {
        super(props);
        
    }
    componentDidMount()
    {
        
        
    }
    render() {
       const { languages,languages_structure } = this.context;
        
        return (
          <div>
                <Accordion defaultActiveKey="-1">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as='div' className='languages-button' variant="link" eventKey="a">
                          {languages_structure.languages.titolo}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="a">
                        <Card.Body>{
                                    languages.map((language, i) => 
                                  {     
                                      return (<div className='language-button' key={language.prefix}><Link  to={language.prefix+"/"} >{language.name}</Link> <br/></div>) 
                                  })

                              }</Card.Body>
                      </Accordion.Collapse>
                    </Card>

                </Accordion>
            
          </div>
        );
    }
    
}
